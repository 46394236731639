


































































import Vue, { VueConstructor } from 'vue'
import BalanceCard from '@/components/BalanceCard.vue'
import Organization from '../../../../../store/models/Organization'
import CreditLineDeadline from '../../../../../store/models/CreditLineDeadline'
import AccountMixin from '../../../../../mixins/accountMixin'

interface Refund {
  transactionNumber: number
  sfd: string
  creditLine: string
  initiatorName: string
  dateTime: string
  amount: number
  mobileOperator: string
}

interface ComponentData {
  items: Refund[]
  columns: Record<string, string | boolean>[]
}

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    BalanceCard
  },

  data(): ComponentData {
    return {
      items: [],
      columns: [
        {
          label: 'N° Transaction',
          field: 'refundTransaction.reference'
        },
        {
          label: 'SFD',
          field: 'organization.name',
          searchable: true
        },
        {
          label: 'Ligne de crédit',
          field: 'creditLine.name',
          searchable: true
        },
        {
          label: 'Date/Heure',
          field: 'updatedAt'
        },
        {
          label: 'Montant',
          field: 'amount'
        },
        {
          label: 'Opérateur Mobile',
          field: 'otm',
          align: 'center',
          searchable: true
        },
        {
          label: 'Actions',
          field: '_actions',
          align: 'right'
        }
      ]
    }
  },

  mixins: [AccountMixin],

  computed: {
    organization(): Organization {
      return Organization.loggedIn()
    },

    deadlines(): CreditLineDeadline[] {
      return CreditLineDeadline.query()
        .withAll()
        .where('state', 'paid')
        .all()
    },
    moovBalance(this: any): number {
      return this.soldeRembourssementMoov
    },
    mtnBalance(this: any): number {
      return this.soldeRembourssementMtn
    },
    totalBalance(this: any): number {
      return this.moovBalance + this.mtnBalance
    }
  },

  created() {
    CreditLineDeadline.api().fetchAll({
      includes: ['organization', 'refundTransaction', 'creditLine']
    })
  },

  methods: {
    onSeeDetails() {
      // this.$router.push({ name: 'refunds.details', params: { refundId: id } })
    }
  }
})
