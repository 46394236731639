var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-box',[_c('div',{staticClass:"container px-10 py-10 mx-auto"},[_c('div',{staticClass:"grid grid-cols-3 gap-4"},[_c('balance-card',{attrs:{"title":"Montant Total","subtitle":"Solde actuel","value":_vm.totalBalance,"currency":"","icon":"amount"}}),_c('balance-card',{attrs:{"title":"Montant Chez MOOV","subtitle":"Solde actuel","value":_vm.moovBalance,"icon":"moov","currency":""}}),_c('balance-card',{attrs:{"title":"Montant Chez MTN","subtitle":"Solde actuel","value":_vm.mtnBalance,"currency":"","icon":"mtn"}})],1)]),_c('router-view'),_c('div',{staticClass:"container px-10 pb-10"},[_c('v-data-table',{attrs:{"title":"Liste des Remboursements","items":_vm.deadlines,"columns":_vm.columns,"hide-add-action":true,"sort-by":"sfd","paginated":""},scopedSlots:_vm._u([{key:"otm",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"uppercase"},[_vm._v(_vm._s(item.otm))])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.monthlyAmount))+" ")]}},{key:"updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.updatedAt))+" ")]}},{key:"_actions",fn:function(ref){
var refund = ref.item;
return [_c('v-button',{attrs:{"small":"","type":"button","color":"primary","to":{
            name: 'finances.creditlines.refunds',
            params: { creditLineId: refund.creditLineId },
            query: { otm: refund.otm }
          }}},[_vm._v("Voir détails")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }